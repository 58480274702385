const Months = [
  { id: "01", name: "january" },
  { id: "02", name: "february" },
  { id: "03", name: "march" },
  { id: "04", name: "april" },
  { id: "05", name: "may" },
  { id: "06", name: "june" },
  { id: "07", name: "july" },
  { id: "08", name: "august" },
  { id: "09", name: "september" },
  { id: "10", name: "october" },
  { id: "11", name: "november" },
  { id: "12", name: "december" }
];

const CreditCardTypes = [
  { id: "visa", name: "Visa" },
  { id: "mastercard", name: "Mastercard" },
  { id: "american-express", name: "American Express" },
  { id: "diners-club", name: "Diners Club" },
  { id: "discover", name: "Discover" },
  { id: "jcb", name: "JCB" },
  { id: "unionpay", name: "UnionPay" },
  { id: "maestro", name: "Maestro" },
  { id: "mir", name: "Mir" },
  { id: "elo", name: "Elo" },
  { id: "hiper", name: "Hiper" },
  { id: "hipercard", name: "Hipercard" }
];

export enum TransactionTypes {
  SALE_DIRECT = 1,
  AUTHORIZE_DIRECT = 2,
  SALE_LINK = 3,
  AUTHORIZE_LINK = 4,
  SALE_EXECUTE = 5,
  AUTHORIZE_EXECUTE = 6,
  CAPTURE = 7,
  REFUND = 8,
  SAVE_CARD = 9,
  SUBSCRIBE_DIRECT = 10,
  SUBSCRIBE_LINK = 11,
  AGREEMENT_EXECUTE = 12,
  AGREEMENT_AUTHORIZE = 13,
  AGREEMENT_CAPTURE = 14,
  AGREEMENT_CANCEL = 15,
  RECURRING_PAYMENT = 16,
  SUBSCRIBE_SAVED_CARD = 17,
  BANK_TRANSFER = 18,
  MANUAL_PAYMENT = 19,
  MANUAL_REFUND = 20,
  WALLET = 21,
  WALLET_REFUND_OUT = 22,
  REFUND_AUTHORIZE = 23,
  REFUND_CAPTURE = 24,
  PAYOUT = 26
}

export enum QUERY_PARAMS {
  ABORT = "abort",
  ACCOUNT_ID = "aid",
  ATTEMPT = "attempt",
  AUTHORIZATION_UUID = "authorization_uuid",
  AUTH_ERROR = "auth_error",
  AUTO_PAY = "auto_pay",
  BASKET_ID = "bid",
  BASKET_PRODUCT_ID = "bpid",
  BILLING_CYCLE_MONTHS = "bcm",
  CATEGORY_ID = "catid",
  CLIENT_ID = "client_id",
  COUPONS = "coupons",
  CROSS_SELL_PRODUCT = "csp",
  CURRENCY = "curr",
  CURRENCY_CODE = "currency",
  CURRENCY_ID = "currId",
  DATE = "date",
  EMAIL_ID = "email_id",
  FAILED = "failed",
  GATEWAY_PROVIDER_ID = "gpid",
  HASH = "hash",
  INIT = "init",
  INIT_PAY = "init_pay",
  LANG = "lang",
  LOCALE = "locale",
  MODAL_REQUEST_ID = "modal_request_id",
  OPERATION_ID = "operation_id",
  ORDER_ID = "oid",
  ORDER_TEMPLATE_CODE = "order_template_code",
  PAYMENT_DETAILS_ID = "payment_details_id",
  PAYMENT_METHOD_TYPE = "pmt",
  PAYMENT_SUCCESS = "payment_success",
  PRODUCT_FIELDS = "pfields",
  PRODUCT_ID = "pid",
  QUANTITY = "qty",
  READ_MORE = "read_more",
  SEARCH = "search",
  STORE_SUCCESS = "store_success",
  SUBPRODUCT_IDS = "sub_pids",
  SUBPRODUCT_QUANTITY = "subproduct_qty",
  SUCCESS = "success",
  USERNAME = "username",
  VIEW = "view"
}

export enum TransactionStatus {
  CONFIRMED = "CONFIRMED",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
  FRAUD = "FRAUD",
  OK = "OK",
  PENDING = "PENDING",
  REDIRECT = "REDIRECT",
  THREE_D_AUTH = "3D-AUTH",
  WAITING = "WAITING"
}

export const ImageUploadTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/webp",
  "image/bmp",
  "image/svg+xml"
];

export const AdditionalCurrencyRecalculationTypes = [
  { value: 0, key: 0, label: "never_recalculate" },
  { value: 1, key: 1, label: "always_recalculate" },
  { value: 2, key: 2, label: "brand_like_recalculate" }
];

export enum BrandConfigKeys {
  ACCOUNTING_REVENUE_RECOGNITION = "invoices.common.accounting_revenue_recognition",
  AFFILIATES_DEFAULT_REDIRECT_LINK = "affiliate_systems.settings.default_redirect",
  ALLOWED_UPLOAD_FILE_TYPES = "security.uploads.allowed_upload_file_types",
  ANALYTICS_GA_MEASUREMENT_ID = "analytics.google.measurement_id",
  ANALYTICS_GTM_CONTAINER_ID = "analytics.gtm.container_id",
  BASKET_DEFAULT_CURRENCY = "ui.basket.default_currency",
  BASKET_HIDE_NOTES = "invoices.basket.hide_notes",
  BASKET_PAYMENT_TERM_DESCRIPTIONS = "ui.basket.payment_term_descriptions",
  BILLING_AUTOMATION_CHARGE_INTERVAL = "billing.automation.before_due_date_charge_interval",
  BILLING_AUTOMATION_CHARGE_INTERVAL_DD = "billing.automation.before_due_date_charge_interval_dd",
  BILLING_DIFFERENT_CURRENCY_PAYMENT_ENABLED = "billing.payment_currencies.enable_different_currency_payment",
  BILLING_GATEWAY_FORCE_AUTO_PAYMENT = "billing.gateway.force_auto_payment_for_stored_details",
  BILLING_GATEWAY_FORCE_CARD_STORAGE = "billing.gateway.force_card_storage",
  BILLING_TERM_DISPLAY = "ui.basket.billing_term_display",
  CHECKOUT_FLOW = "ui.checkout.checkout_flow",
  CHECKOUT_HIDE_DISCOUNT_CODE_FIELD = "ui.checkout.hide_promotions_field",
  CHECKOUT_REQUIRE_PHONE = "invoices.common.require_phone_for_orders",
  CHECKOUT_SUMMARY_COLOR_STOP1 = "ui.checkout.checkout_summary_color_stop1",
  CHECKOUT_SUMMARY_COLOR_STOP2 = "ui.checkout.checkout_summary_color_stop2",
  CHECKOUT_SUMMARY_CONTRAST_MODE = "ui.checkout.checkout_summary_contrast_mode",
  CLIENT_NOTES_AND_SECRETS_ENABLED = "security.ui.allow_vault",
  CLIENT_TICKET_SCHEDULING_ENABLED = "tickets.tickets.client_can_schedule",
  DEFAULT_CLIENT_HOMEPAGE = "ui.client_area.homepage",
  DEFAULT_PAYMENT_PERIOD = "invoices.common.default_payment_period",
  DISABLE_CLIENT_REGISTRATION = "ui.client_area.hide_registration_forms",
  GUEST_CHECKOUT_ENABLED = "invoices.guest_checkout.enabled",
  INVOICE_CONSOLIDATION_BASE_RULE = "invoices.consolidation.base_rule",
  INVOICE_CONSOLIDATION_DATE = "invoices.consolidation.base_rule_date_of_month_day",
  INVOICE_CONSOLIDATION_ENABLED = "invoices.consolidation.enabled",
  INVOICE_CONSOLIDATION_RESTRICT_TO_STAFF = "invoices.consolidation.restrict_to_staff",
  INVOICE_CONSOLIDATION_WEEK_DAY = "invoices.consolidation.base_rule_day_of_week",
  INVOICE_CREDIT_NOTE_NUMBER_SEPARATE = "invoices.common.credit_note_number_separate_sequence",
  PARTIAL_PAYMENTS_ENABLED = "billing.gateway.client_allow_partial_payments",
  PAY_LATER_ENABLED = "invoices.common.is_available_pay_later",
  PREVENT_CARD_REMOVAL_IF_LAST = "billing.gateway.allow_card_removal_replacement",
  PRICE_DISPLAY_TYPE = "invoices.common.display_price_type",
  REQUIRE_ADDRESS_FOR_ORDERS = "invoices.common.require_address_for_orders",
  REQUIRE_COMPANY_FOR_ORDERS = "invoices.common.require_company_for_orders",
  REQUIRE_PHONE_ON_REGISTRATION = "ui.client_registration.require_phone",
  REQUIRE_REGION_IN_ADDRESS = "invoices.common.required_region_in_address",
  SHOP_TRUNCATE_DESCRIPTIONS = "ui.basket.truncate_product_description",
  SHOW_CLIENT_STORE = "ui.client_area.show_catalog",
  SHOW_PROMOTIONS_ON_CATALOG = "invoices.common.show_promotions_on_catalog",
  SHOW_PROMOTION_AS = "invoices.common.show_promotion_as",
  SUBSCRIPTIONS_ALLOW_IMMEDIATE_CANCELLATION = "subscriptions.cancellation.allow_client_immediate_cancellation_request",
  SUBSCRIPTIONS_CANCEL_INTERVAL = "subscriptions.contract.invoice_contract_cancel_interval",
  SUBSCRIPTIONS_CLOSE_INTERVAL = "subscriptions.contract.invoice_contract_close_interval",
  SUBSCRIPTIONS_MANUAL_RECURRING = "subscriptions.contract.manual_recurring_days_before_due_date",
  SUBSCRIPTIONS_MONEY_BACK_PERIOD = "subscriptions.contract.product_recommended_money_back_period",
  SUBSCRIPTIONS_RESTRICT_MANUAL_RECURRING = "subscriptions.contract.restrict_manual_recurring_before_due_date",
  SUBSCRIPTIONS_SHOW_CUSTOM_FIELDS_FOR_AUTO_RENEW_OFF = "subscriptions.cancellation.custom_fields_on_end_of_billing_term",
  SUBSCRIPTIONS_SUSPEND_INTERVAL = "subscriptions.contract.invoice_contract_suspend_interval",
  SUPPORT_PIN_ENABLED = "tickets.support.support_pin_enabled",
  UI_CLIENT_APP_DISABLE_SUPPORT_SYSTEM = "ui.client_area.disable_support_system",
  UI_CLIENT_APP_PAGE_AFTER_LOGIN = "ui.client_area.page_after_login",
  UI_ENTER_KEY_ACTION = "ui.client_area.enter_key_action",
  UI_LOGO_URL = "ui.client_area.logo_url",
  UI_PRICE_BEFORE_DISCOUNT_POSITION = "ui.client_area.price_before_discount_position",
  UPMIND_AFFILIATES_CUSTOMER_CONTROLS_ENABLED = "affiliate_systems.upmind.customer_controls_enabled",
  UPMIND_AFFILIATES_ENABLED = "affiliate_systems.upmind.enabled",
  WALLET_DEFAULT_NEGATIVE_ALLOWANCE = "billing.wallet.default_negative_allowance",
  WALLET_NEGATIVE_BALANCE_ENABLED = "billing.wallet.enable_negative_wallet_balance"
}

export enum OrgFeatureKeys {
  CREATE_USER_API_TOKENS = "package.enabled_features.create_user_api_tokens",
  BULK_NOTIFICATIONS_ENABLED = "package.enabled_features.bulk_notifications",
  MULTI_BRAND_ENABLED = "package.enabled_features.multi_brand",
  PRODUCT_PROVISIONING_ENABLED = "package.enabled_features.product_provisioning",
  REMOVE_UPMIND_BRANDING_ENABLED = "package.enabled_features.remove_upmind_branding",
  UNLIMITED_PAYMENT_GATEWAYS = "package.enabled_features.unlimited_payment_gateways",
  UNLIMITED_PROVISION_CONFIGURATIONS = "package.enabled_features.unlimited_provisioning_configurations",
  WEBHOOKS = "package.enabled_features.webhooks"
}

export const OrgConfigKeys = {
  ...OrgFeatureKeys
};

export enum OrgPackageLimits {
  BRANDS = "brands",
  CLIENTS = "clients",
  INVOICES = "invoices",
  LOGS_STORAGE = "logs_storage",
  MONTHLY_BILLINGS = "monthly_billings",
  PAYMENT_GATEWAYS = "payment_gateways",
  PRODUCTS = "products",
  PROVISION_CONFIGURATIONS = "provisioning_configurations",
  USERS = "users",
  WEBHOOKS = "webhooks",
  WIDGETS = "widgets"
}

export enum CheckoutFlows {
  ONE_PAGE = "one_page",
  STEPPED = "stepped"
}

export enum PriceBeforeDiscountPositions {
  AFTER = "after",
  BEFORE = "before"
}

export enum BasketTypes {
  NEW = "new"
}

export enum BasketCurrencySource {
  /** Default – Use brand currency */
  BRAND = "brand",
  /** Determine currency based on active locale */
  LANGUAGE = "language"
}

export enum BasketEvents {
  BASKET_CHANGED = "basketChanged",
  BASKET_INITIATED = "basketProviderInitiated",
  BASKET_NEW_CLIENT_SELECTED = "newClientSelected",
  BASKET_NOT_FOUND = "basketNotFound",
  BASKET_NO_LONGER_EXISTS = "basketNoLongerExists",
  BASKET_PRODUCT_QUICKLY_ADDED = "productQuicklyAdded",
  BASKET_RESETTED = "basketResetted",
  BASKET_SET_FOR_CLIENT = "basketSetForClient",
  BASKET_VIEW_CATEGORY = "viewCategory",
  BASKET_VIEW_PRODUCT = "viewProduct"
}

export const IPWhitelistOptions = (vm: any) => [
  {
    leftLabel: vm.$t("_.inherit_brand_default"),
    value: 0
  },
  {
    leftLabel: vm.$t("_.none"),
    desc: vm.$t("_sentence.whitelist_ip_none_desc"),
    value: 1
  },
  {
    leftLabel: vm.$t("_.secure_with_whitelist_ips"),
    desc: vm.$t("_sentence.whitelist_ip_desc"),
    value: 2
  }
];

export enum IPWhitelistTypes {
  INHERIT = 0,
  NONE = 1,
  SECURE = 2
}

export enum ListDisplayMode {
  GRID = "grid",
  INBOX = "inbox",
  TABLE = "table",
  TREE = "tree"
}

export enum SemanticTypes {
  DOMAIN_NAMES = "domain_name"
}

export const STATS_ALL_CURRENCY_CODE = "ALL";
export const STATS_DEFAULT_CURRENCY_CODE = STATS_ALL_CURRENCY_CODE;

export { Months, CreditCardTypes };
